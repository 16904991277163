import { ignoreBlankValues } from './ignoreBlankValues';

export function getStableJson(_key: string, value: unknown): unknown {
    // Ensure keys are sorted in objects
    if (value instanceof Object && !(value instanceof Array)) {
        return Object.keys(value)
            .sort()
            .reduce((sorted, key) => {
                sorted[key] = value[key];
                return sorted;
            }, {});
    }

    return ignoreBlankValues(_key, value);
}
