import { useEffect, useState } from 'react';
import { getURL } from '../common/util/network/url/getURL';

/**
 * Hook for keeping track of a URL
 *
 * Ensure that the URL object only updates when the resulting string changes
 *
 * @param url
 * @returns
 */
export function useURL(url: URL | string): string {
    const [value, setValue] = useState<string>();

    useEffect(() => {
        const newUrl = getURL(url);
        const urlString = newUrl?.toString();
        setValue(urlString);
    }, [url, setValue]);

    return value;
}
