import { useGetToken } from '@/contexts/TokenContext/useToken';
import useSWR, { SWRConfiguration } from 'swr';
import { authenticatedFetch } from './authenticatedFetch';
import { useURL } from '@/hooks/useURL';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { minutesToMilliseconds, secondsToMilliseconds } from 'date-fns';
import { useCallback } from 'react';

const swrOptions: SWRConfiguration = {
    // Don't try to grab again on focus for at least 5 minutes
    focusThrottleInterval: minutesToMilliseconds(5),
    // If multiple requests come in within the same 15 seconds, make sure to group them together
    dedupingInterval: secondsToMilliseconds(15),
    // How long to trigger slow loading indicator
    loadingTimeout: secondsToMilliseconds(3),
};

export function useAuthenticatedJsonGet<T>(inputUrl: URL | string): [T, boolean, Error, () => void] {
    const appInsights = useAppInsightsContext();

    // Use a state so that we don't trigger updates unnecessarily
    const url = useURL(inputUrl);
    const getToken = useGetToken();

    const fetcher = useCallback(async () => {
        return authenticatedFetch<T>(appInsights, getToken, url);
    }, [appInsights, getToken, url]);

    const { data, mutate, error, isLoading } = useSWR<T>(url?.toString(), fetcher, swrOptions);

    return [data, isLoading, error, mutate];
}
